import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Cta from "../components/cta";
import Hero from "../components/hero";
import Image from "../components/image";

import { Link, graphql } from "gatsby";
import PropTypes from "prop-types";

function SellerStory({ data }) {
    let d = data.contentfulSellerStory;

    return (
        <Layout>
            <SEO title={d.title} description={d.subtitle} />

            <Hero
                props={{
                    label: d.title,
                    title: d.subtitle,
                    desc: ``,
                }}
            />

            <section>
                <div className="w-full max-w-6xl mx-auto px-4 py-8 md:px-8 md:py-16">
                    <div className="flex flex-wrap w-full">
                        {data.allContentfulSellerStoryPost.nodes.map((item) => (
                            <Link
                                to={`../${item.slug}`}
                                key={item.slug}
                                className="flex flex-col w-full md:w-1/2 mb-10 px-4 transition duration-500 ease-in-out transform hover:-translate-y-2 hover:text-primary dark:hover:text-gray-200"
                            >
                                <div className="w-full bg-indigo-300 h-auto rounded-lg ">
                                    <Image
                                        className="rounded-lg"
                                        filename={`stores/${item.featuredImage}`}
                                    />
                                </div>
                                <div className="w-full px-1 md:px-3 -mt-8 z-10">
                                    <div className=" bg-white dark:bg-gray-700 h-auto rounded-lg z-10 p-6 border border-gray-700">
                                        <p className="font-body text-xl font-normal text-gray-700 dark:text-gray-200">
                                            {item.title}
                                        </p>
                                        <h5 className="mt-6 font-bold">
                                            {item.shopName}
                                        </h5>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </section>

            <Cta />
        </Layout>
    );
}

SellerStory.propTypes = {
    data: PropTypes.object,
};

export const query = graphql`
    {
        contentfulSellerStory {
            title
            subtitle
            stories {
                content
                shop
                person
            }
        }
        allContentfulSellerStoryPost {
            nodes {
                title
                slug
                content {
                    raw
                }
                shopName
                shopOwner
                featuredImage
            }
        }
    }
`;

export default SellerStory;
